import React from 'react'

const about = () => {
  return (
    <div name='about' className='w-full h-screen bg-[#0a192f] text-gray-300'>
        <div className='flex flex-col justify-center items-center w-full h-full'>
<div className='max-w-[1000px] w-full grid grid-cols-2 gap-8'>
    <div className='sm:text-right pb-8 pl-4'>
        <p className='text-4xl font-bold inline border-b-4 border-pink-600'>About</p>
    </div>
    <div>

    </div>
    </div>
    <div className='max-w-[1000px] w-full sm:grid grid-cols-2 gap-8 px-4'>
<div className='sm:text-right text-4xl font-bold'>
    <p>Mijn naam is milou, en ik zit momenteel in mijn derde jaar software development. Ik vind het leuk om me bezig te houden met het ontwerpen en bouwen van een website.</p>
</div>
<div>
    <p>In het eerste jaar heb ik me vooral bezig gehouden met het leren van html, css, sql en php. In het begin van het tweede jaar, periode 5 & 6 heb ik stage gelopen. In deze periode heb ik me bezig gehouden met powerapps en mendix. In periode 7 & 8 zijn we nog meer bezig geweest met sql en php, Ook hebben we een dashboard gebouwd. 
    <br /><br />Voor de rest vind ik het fijn om in me vrije tijd te sporten, te lezen of muziek te luisteren.</p>
    <br />
    <br />
    <br />
    <br />
    <br />
</div>
    </div>
        </div>
    </div>
  )
}

export default about