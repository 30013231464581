import React from 'react';
import WorkImg from '../assets/netfish.png';
import Project from '../assets/pizza2.png';
import Weather from '../assets/weatherApp.png';
import Dashboard from '../assets/SchoolDashboard.png';
import demo5 from '../assets/brv.png';
import Demo from '../assets/demo-video-share.mp4';
import Dashie from '../assets/dashboard2.mp4';
import Demo3 from '../assets/demo3.mp4';
import demo4 from '../assets/weerapp2.mp4';
import demo6 from '../assets/bookclip.mp4';

const Work = () => {
  const Demovideo = () => {
    window.open(Demo, '_blank');
  };

  const Demovideo2 = () => {
    window.open(Dashie, '_blank');
  };

  const Demovideo3 = () => {
    window.open(Demo3, '_blank');
  };

  const Demovideo4 = () => {
    window.open(demo4, '_blank');
  };

  const Demovideo5 = () => {
    window.open(demo6, '_blank');
  };

  return (
    <div name='work' className='w-full md:h-screen text-gray-300 bg-[#0a192f]'>
      <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
        <div className='pb-8'>
          <p className='text-4xl font-bold inline border-b-4 text-gray-300 border-pink-600'>Work</p>
          <p className='py-6'>// bekijk hier wat projecten</p>
        </div>

        {/* Grid layout met 3 boven en 2 onder */}
        <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
          {/* Bovenste rij */}
          <div 
            style={{ backgroundImage: `url(${WorkImg})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div h-64'
          >
            <div className='flex flex-col justify-center items-center opacity-0 group-hover:opacity-100 text-center'>
              <span className='text-2xl font-bold text-white tracking-wider'>
                Video Share Project
              </span>
              <br />
              <span className='text-xs'>3de periode school project Met CRUD systeem</span>
              <div className='pt-8'>
                <a href="#" onClick={Demovideo}>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Demo</button>
                </a>
              </div>
            </div>
          </div>

          <div 
            style={{ backgroundImage: `url(${Project})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div h-64'
          >
            <div className='flex flex-col justify-center items-center opacity-0 group-hover:opacity-100 text-center'>
              <span className='text-2xl font-bold text-white tracking-wider'>
                Pizzeria Webstore
              </span>
              <br />
              <span className='text-xs'>4e periode School project CRUD, inlog en winkelmandje.</span>
              <div className='pt-8'>
                <a href="#" onClick={Demovideo3}>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Demo</button>
                </a>
                <a href="https://github.com/MilouVDVelden/Pizza-Webstore">
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Code</button>
                </a>
              </div>
            </div>
          </div>

          <div 
            style={{ backgroundImage: `url(${Weather})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div h-64'
          >
            <div className='flex flex-col justify-center items-center opacity-0 group-hover:opacity-100 text-center'>
              <span className='text-2xl font-bold text-white tracking-wider'>
                Weather App
              </span>
              <br />
              <span className='text-xs'>Klein eigen project van youtube</span>
              <div className='pt-8'>
                <a href="#" onClick={Demovideo4}>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Demo</button>
                </a>
                <a href="https://github.com/MilouVDVelden/Weather-App">
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Code</button>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-4'>
          <div 
            style={{ backgroundImage: `url(${Dashboard})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div h-64'
          >
            <div className='flex flex-col justify-center items-center opacity-0 group-hover:opacity-100 text-center'>
              <span className='text-2xl font-bold text-white tracking-wider'>
                DashBoard
              </span>
              <br />
              <span className='text-xs'>Dashboard opdracht van 8e periode 2de jaar</span>
              <div className='pt-8'>
                <a href="#" onClick={Demovideo2}>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Demo</button>
                </a>
              </div>
            </div>
          </div>

          <div 
            style={{ backgroundImage: `url(${demo5})` }}
            className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div h-64'
          >
            <div className='flex flex-col justify-center items-center opacity-0 group-hover:opacity-100 text-center'>
              <span className='text-2xl font-bold text-white tracking-wider'>Zomer-project</span>
              <br />
              <span className='text-xs'>zomer-projectje einde 2de jaar voor mezelf</span>
              <div className='pt-8'>
                <a href="#" onClick={Demovideo5}>
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Demo</button>
                </a>
                <a href="https://github.com/MilouVDVelden/Book-review">
                  <button className='text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg'>Code</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work;

