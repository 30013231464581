import React from 'react'

import HTML from '../assets/html.png';
import CSS from '../assets/css.png';
import PHP from '../assets/php-logo.png';
import SQL from '../assets/mysql-logo.png';
import POWERAPPS from '../assets/powerapps-logo.png';
import MENDIX from '../assets/mx-logo.png';

const Skills = () => {
  return (
    <div name='skills' className='w-full h-screen bg-[#0a192f] text-gray-300'>
        <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
            <div>
                <p className='text-4xl font-bold inline border-b-4 border-pink-600'yarn>Skills</p>
                <p className='py-4'>// Dit zijn de progomma's & talen waarmee ik al heb gewerkt.</p>
            </div>

            <div className='w-full grid grid-cols-2 sm:grid-cols-3 gap-4 text-center py-8'>
                <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className='w-20 mt-4 mx-auto' src={HTML} alt="HTML icon" />
                    <p className='my-4'>HTML</p>
                    </div>
                    <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className='w-20 mt-4 mx-auto' src={CSS} alt="HTML icon" />
                    <p className='my-4'>CSS</p>
                    </div>
                    <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className='w-20 mt-4 mx-auto' src={PHP} alt="HTML icon" />
                    <p className='my-4'>PHP</p>
                    </div>
                    <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className='w-20 mt-4 mx-auto' src={SQL} alt="HTML icon" />
                    <p className='my-4'>SQL</p>
                    </div>
                    <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className='w-20 mt-4 mx-auto' src={POWERAPPS} alt="HTML icon" />
                    <p className='my-4'>POWERAPPS</p>
                    </div>
                    <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                    <img className='w-20 mt-4 mx-auto' src={MENDIX} alt="HTML icon" />
                    <p className='my-4'>MENDIX</p>
                    </div>
            </div>
        </div>
    </div>
  );
};

export default Skills